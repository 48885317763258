<template>
  <div class="wrapper d-flex align-top">
    <v-menu
      :attach="attachEl"
      content-class="background-translucid"
      min-width="max-content"
      transition="slide-y-transition"
      origin="right top"
      :nudge-bottom="displayToTop ? 0 : 36"
      :nudge-top="displayToTop ? 12 : 0"
      :top="displayToTop"
      :left="displayToLeft"
      :z-index="this.displayZIndex || 2"
      v-model="isOpen"
    >
      <template v-slot:activator="{ on }">
        <v-badge
          offset-y=10
          offset-x=8
          color="translucid"
          :value="ids.length"
          ref=activator
        >
          <div v-on="on">
            <icon-mark
              :color="isOpen ? 'secondary' : undefined"
              :icon-name="iconName(type)"
              :icon-mark-props="iconMarkProps"
            />
          </div>
          <template v-slot:badge>{{computedLength}}</template>
        </v-badge>
      </template>
      <v-card
        ref="content"
        class="background-plain ma-0 items"
        @click.stop
      >
        <v-list>
          <v-list-item
            :key="i"
            v-for="(id, i) in computedIds"
            link
            @click="navigateTo(id, type)"
          >
            <computed-document-ref
              :id="id"
              :document-type="type"
              :show-detail="true"
              :options="{ hideNavigation: false }"
            />
          </v-list-item>
        </v-list>
        <div v-if="hasMoreItems">
          <span class="ml-8">...</span>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

import navigation from '@/navigationController'
import DisplayableInContainer from '@/pages/search/mixins/displayable-in-container'

export default {
  mixins: [DisplayableInContainer],
  components: {
    ComputedDocumentRef: () => import('@/components/documents-ref/computed-document-ref'),
    IconMark: () => import('@/components/icon-mark')
  },
  computed: {
    computedIds () {
      return this.ids.slice(0, 9)
    },
    hasMoreItems () {
      return this.ids.length > 9
    },
    computedLength () {
      return this.hasMoreItems ? '9+' : this.ids.length
    }
  },
  methods: {
    navigateTo (id, type) {
      navigation.navigateTo(id, type)
    },
    iconName (type) {
      if (!type) { return }
      return `i.${this.toPascalCase(type)}`
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  props: {
    accountId: String,
    iconProps: Object,
    ids: Array,
    type: String,
    iconMarkProps: Object
  }
}
</script>

<style lang="stylus" scoped>
@css {
.items {
  max-width: max(300px, 30vw);
  z-index: 100 !important;
}
}

.wrapper
  position relative

>>> .theme--dark .v-badge__badge
  color white !important

>>> .theme--light .v-badge__badge
  color dimgrey !important
</style>
